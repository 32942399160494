.importCanvass{
	margin-top: 100px;
    max-width: 625px;
    margin-left: auto;
    margin-right: auto;
}
.stepContent{
	padding: 15px;
	text-align: center;
}
div.buttons{
	text-align: center;
	margin-top: 20px;
}
div.prevNext button{
	  background: transparent!important;
}
div.buttons button{
	margin-left: 40px!important;
	margin-right: 40px!important;
}
div.mobileWarning {
	padding: 10px;
	color: #db2828;
}
div.getAnnotations{
	text-align: left;
}
.installChromeExtension{
	background:none!important;
	color:#4183c4!important;
	border:none!important; 
	padding:0!important;
	font: inherit!important;
	/*border is optional*/
	border-bottom:1px solid #444; 
	cursor: pointer!important;
	font-weight: normal!important;
}

