.HowToHeading {
	text-align: center;
}

.HowToContent {
	padding: 30px;
}

.annotationsCanvass{
	padding-top: 25px;
}

.bookTable {
	table-layout: fixed;
	width: 100%;
}

.bookSettings {
	vertical-align: top;
	width: 20px;
	color: rgba(0,0,0,.4);
}

@media only screen and (min-width: 768px){
	.shrunkCanvass{
		padding-left: 32px;
		padding-right: 30px;
		float: right;
	}
	.fullCanvass{
		padding-left: 60px;
		padding-right: 60px;
		float: none;
	}
	.annotationsCanvass{
		transition: width 500ms ease, float 500ms ease;
		transition-delay: 0ms;
		padding-bottom: 30px;
	}
	
}

.shareButton .icon{
	background-color: inherit!important;
	border-right: solid 1px;
}

@media only screen and (max-width: 767px) {
	.mobileCenter{
		text-align: center;
	}
}

.annotations{
	max-width: 54em!important;
	margin-left: auto!important;
	margin-right: auto!important;
}

/*Fix bug with semantic ui - https://github.com/Semantic-Org/Semantic-UI-React/issues/2558*/
.ui.page.modals.transition.visible {
    display: flex !important;
}

.bookImage{
	border: 1px solid rgba(0,0,0,.1)!important;
}
