.annotationListHeader{
	max-width: 54em;
	margin-left: auto!important;
	margin-right: auto!important;
	margin-top: 40px!important;
}
.shareButton .icon{
	background-color: inherit!important;
	border-right: solid 1px;
}
.shareButton {
	margin-top: 5px!important;
}

@media only screen and (max-width: 767px) {
	.mobileCenter{
		text-align: center;
	}
}

/*Fix bug with semantic ui - https://github.com/Semantic-Org/Semantic-UI-React/issues/2558*/
.ui.page.modals.transition.visible {
    display: flex !important;
}

.bookImage{
	border: solid 1px black!important;
}
a.bookTitle{
	color: black;
}
