.annotationSelector{
	background-color: white;
	border-right: 1px solid rgba(34,36,38,.15);
}
.annotationSelector .item{
	text-align: left!important;
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}
.menuContainer{
	padding-left: 30px;
}

#booksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendsBooksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendMenuHeader {
	padding-bottom: 8px;
}

.annotationSelector ul{
	padding-left: 0px;
}
.annotationSelector li{
	display: block;
	padding: 5px 10px
}

.is-open i.right{
	display: none;
}

.is-closed i.down{
	display: none;
}

.annotationSelector div.menu{
	box-shadow: none!important;
	border-radius: 0px;
	border-top: 0px;
}
.borderless {
	border: none!important;
}
.sidebarSearchMenu{
	border-right: 0px!important;
	border-left: 0px!important;
}
.sidebarSearchMenu .item{
	padding-left: 5px!important;
	padding-right: 5px!important;
}
.sortBy{
	width: 140px;
}
.sortBy span{
	margin: auto;
}
.toggle{
	margin: auto;
}
.toggle:before{
	width: 0px!important;
}

.mainToggleMenu {
    padding-left: 0px !important;
    margin-left: 0px !important;
    width: 50px !important;
}
