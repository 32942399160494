.bookImage{
  border: solid 1px black!important;
}

div.singleAnnotation .logoHeader{
	-webkit-box-shadow: none!important;
	-moz-box-shadow: none!important;
	box-shadow: none!important;
}
div.singleAnnotation .logoText{
	color: #808080!important;
	font-family: Baskerville serif !important;
	font-size: 20px;
}
div.singleAnnotation .ui.fixed.sticky + .container {
	margin-top: 39px;
}
div.singleAnnotation .header{
	padding: 8px;
	font-style: italic;
	background: transparent;
}
div.singleAnnotation .meta{
	padding-left: 8px;
	margin-bottom: 20px;
}
div.singleAnnotation .annotation{
	max-width: 750px!important;
}
.noteFooter {
	background-color: rgb(199, 204, 205);
	text-align: center;
	padding-top: 20px;
	margin-top: 50px;
	height: 130px;
	font-size: 16px;
	color: white;
	width: 100%;

}
@media (min-height: 700px) {
	.noteFooter {
		bottom: 0;
	}
}
.annotationContent{
	font-size: 21px;
	line-height: 1.58;
	font-family: Georgia,Cambria,"Times New Roman",Times,serif;
}

@media only screen and (max-width: 767px){
	.annotationContent {
		font-size: 18px;
	}
}
