body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
button{
	cursor: pointer;
}


@media only screen and (max-width: 768px){
	.showMenu{
		display: block!important;
	}
	.hideMenu{
		display: none!important;
	}
}
.toggle{
	margin-right: auto;
}
.title{
	margin-right: auto;
	margin-left: auto;
	font-weight: bold;
}
.drop{
	margin-left: auto;
}
.menuBookTitle {
  text-overflow: ellipsis;
  max-width: 660px;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}
.TopMenu {
  margin-left: 0px !important;
}
.ui.fluid.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.segment.pushable {
  border-radius: 0px !important;
}
.mainToggleMenu {
  padding-left: 0px !important;
  margin-left: 0px !important;
  width: 50px !important;
}
.mainToggleMenu button {
  margin-top: 5px !important;
  margin-left: 5px !important;
}
.annotationsSegement {
  min-height: 100vh;
  margin-top: 42px !important;
  background-color: #f5f7f9!important;
  border-top: 0px!important;
}
.annotationsSegement .pusher{
  background-color: #f5f7f9;
}

/* Page Context fix for sticky sidebar */
.pushable:not(body) {
  -webkit-transform: none;
          transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.ui.sidebar{
  overflow-y: hidden!important;
  margin-top: 44px!important;
}

.ui.sidebar:hover{
  overflow-y: auto!important;
}
.pusher{
  -webkit-transition: all 0.5s ease!important;
  transition: all 0.5s ease!important;
}
.annotationSelector{
	background-color: white;
	border-right: 1px solid rgba(34,36,38,.15);
}
.annotationSelector .item{
	text-align: left!important;
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}
.menuContainer{
	padding-left: 30px;
}

#booksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendsBooksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendMenuHeader {
	padding-bottom: 8px;
}

.annotationSelector ul{
	padding-left: 0px;
}
.annotationSelector li{
	display: block;
	padding: 5px 10px
}

.is-open i.right{
	display: none;
}

.is-closed i.down{
	display: none;
}

.annotationSelector div.menu{
	box-shadow: none!important;
	border-radius: 0px;
	border-top: 0px;
}
.borderless {
	border: none!important;
}
.sidebarSearchMenu{
	border-right: 0px!important;
	border-left: 0px!important;
}
.sidebarSearchMenu .item{
	padding-left: 5px!important;
	padding-right: 5px!important;
}
.sortBy{
	width: 140px;
}
.sortBy span{
	margin: auto;
}
.toggle{
	margin: auto;
}
.toggle:before{
	width: 0px!important;
}

.mainToggleMenu {
    padding-left: 0px !important;
    margin-left: 0px !important;
    width: 50px !important;
}


.mainToggleMenu {
    padding-left: 0px !important;
    margin-left: 0px !important;
	width: 50px !important;
	margin-top: 44px;
}

.HowToHeading {
	text-align: center;
}

.HowToContent {
	padding: 30px;
}

.annotationsCanvass{
	padding-top: 25px;
}

.bookTable {
	table-layout: fixed;
	width: 100%;
}

.bookSettings {
	vertical-align: top;
	width: 20px;
	color: rgba(0,0,0,.4);
}

@media only screen and (min-width: 768px){
	.shrunkCanvass{
		padding-left: 32px;
		padding-right: 30px;
		float: right;
	}
	.fullCanvass{
		padding-left: 60px;
		padding-right: 60px;
		float: none;
	}
	.annotationsCanvass{
		-webkit-transition: width 500ms ease, float 500ms ease;
		transition: width 500ms ease, float 500ms ease;
		-webkit-transition-delay: 0ms;
		        transition-delay: 0ms;
		padding-bottom: 30px;
	}
	
}

.shareButton .icon{
	background-color: inherit!important;
	border-right: solid 1px;
}

@media only screen and (max-width: 767px) {
	.mobileCenter{
		text-align: center;
	}
}

.annotations{
	max-width: 54em!important;
	margin-left: auto!important;
	margin-right: auto!important;
}

/*Fix bug with semantic ui - https://github.com/Semantic-Org/Semantic-UI-React/issues/2558*/
.ui.page.modals.transition.visible {
    display: -webkit-flex !important;
    display: flex !important;
}

.bookImage{
	border: 1px solid rgba(0,0,0,.1)!important;
}

.annotationToolbar button,
.annotationToolbar a{
	margin-right: 10px!important;
}

@media only screen and (min-width: 767px) {
	.annotationToolbar div{
		float: left;
	}
}
@media only screen and (max-width: 767px) {
	.annotationToolbar div{
		text-align: center;
		margin-bottom: 10px;
	}
}

.buttonLink {
	 background:none!important;
	 color:inherit!important;
	 border:none!important; 
	 padding:0!important;
	 font: inherit!important;
	 /*border is optional*/
	 border-bottom:1px solid #444; 
	 cursor: pointer!important;
	 font-weight: normal!important;
}
button.buttonLink:hover {
	color: #1e70bf!important;
}
.noteTable {
  table-layout: fixed;
  width: 100%;
}

.noteIcon {
  vertical-align: top;
  width: 25px;
}

.noteDate {
  vertical-align: top;

  float: right;
  color: rgba(0, 0, 0, 0.4);
}

.dateCreated {
  font-size: 10px;
  color: gray;
  min-width: 65px;
  display: inline-block;
}

.note {
  margin-bottom: 1em;
}

.bookName {
	font-size: 30px;
}

.annotation {
	text-align: left;
}
.annotation .location{
	float: right;
}
.annotation .location a{
	float: right;
	color: rgba(0,0,0,.4);
}
.annotation div.header{
	font-size: 17px !important;
}
div.notesHeader{
	margin-top: 20px;
	font-size: .9em;
	color: gray;
}
hr.notesHeader{
	margin-top: 0px;
	margin-bottom: 10px;
	border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.annotationContent{
	font-size: 21px;
	line-height: 1.58;
	font-family: Georgia,Cambria,"Times New Roman",Times,serif;
}

@media only screen and (max-width: 767px){
	.annotationContent {
		font-size: 18px;
	}
}

.annotationListHeader{
	max-width: 54em;
	margin-left: auto!important;
	margin-right: auto!important;
	margin-top: 40px!important;
}
.shareButton .icon{
	background-color: inherit!important;
	border-right: solid 1px;
}
.shareButton {
	margin-top: 5px!important;
}

@media only screen and (max-width: 767px) {
	.mobileCenter{
		text-align: center;
	}
}

/*Fix bug with semantic ui - https://github.com/Semantic-Org/Semantic-UI-React/issues/2558*/
.ui.page.modals.transition.visible {
    display: -webkit-flex !important;
    display: flex !important;
}

.bookImage{
	border: solid 1px black!important;
}
a.bookTitle{
	color: black;
}

.description div{
    margin-top: 10px;
    margin-bottom: 10px;
}

.exportOptions{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.exportOptions .field{
    padding-left: 20px;
}

.description button{
    margin-top: 15px;
}

.actions{
    text-align: center!important;
}
.howToList li {
  padding: 5px;
}
.howToSegment{
  max-width: 54em;
  margin-left: auto!important;
  margin-right: auto!important;
  margin-top: 40px!important;
}

.annotationSelector{
	background-color: white;
	border-right: 1px solid rgba(34,36,38,.15);
}
.annotationSelector .item{
	text-align: left!important;
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}
.menuContainer{
	padding-left: 30px;
}

#booksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendsBooksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendMenuHeader {
	padding-bottom: 8px;
}

.annotationSelector ul{
	padding-left: 0px;
}
.annotationSelector li{
	display: block;
	padding: 5px 10px
}

.is-open i.right{
	display: none;
}

.is-closed i.down{
	display: none;
}

.annotationSelector div.menu{
	box-shadow: none!important;
	border-radius: 0px;
	border-top: 0px;
}
.borderless {
	border: none!important;
}
.sidebarSearchMenu{
	border-right: 0px!important;
	border-left: 0px!important;
}
.sidebarSearchMenu .item{
	padding-left: 5px!important;
	padding-right: 5px!important;
}
.sortBy{
	width: 140px;
}
.sortBy span{
	margin: auto;
}
.toggle{
	margin: auto;
}
.toggle:before{
	width: 0px!important;
}


.annotationSelector{
	background-color: white;
	border-right: 1px solid rgba(34,36,38,.15);
}
.annotationSelector .item{
	text-align: left!important;
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}
.menuContainer{
	padding-left: 30px;
}

#booksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendsBooksMenuHeader {
	font-size: 18px;
	padding-bottom: 8px;
}

#friendMenuHeader {
	padding-bottom: 8px;
}

.annotationSelector ul{
	padding-left: 0px;
}
.annotationSelector li{
	display: block;
	padding: 5px 10px
}

.is-open i.right{
	display: none;
}

.is-closed i.down{
	display: none;
}

.annotationSelector div.menu{
	box-shadow: none!important;
	border-radius: 0px;
	border-top: 0px;
}
.borderless {
	border: none!important;
}
.sidebarSearchMenu{
	border-right: 0px!important;
	border-left: 0px!important;
}
.sidebarSearchMenu .item{
	padding-left: 5px!important;
	padding-right: 5px!important;
}
.sortBy{
	width: 140px;
}
.sortBy span{
	margin: auto;
}
.toggle{
	margin: auto;
}
.toggle:before{
	width: 0px!important;
}

.mainToggleMenu {
    padding-left: 0px !important;
    margin-left: 0px !important;
    width: 50px !important;
}

.importCanvass{
	margin-top: 100px;
    max-width: 625px;
    margin-left: auto;
    margin-right: auto;
}
.stepContent{
	padding: 15px;
	text-align: center;
}
div.buttons{
	text-align: center;
	margin-top: 20px;
}
div.prevNext button{
	  background: transparent!important;
}
div.buttons button{
	margin-left: 40px!important;
	margin-right: 40px!important;
}
div.mobileWarning {
	padding: 10px;
	color: #db2828;
}
div.getAnnotations{
	text-align: left;
}
.installChromeExtension{
	background:none!important;
	color:#4183c4!important;
	border:none!important; 
	padding:0!important;
	font: inherit!important;
	/*border is optional*/
	border-bottom:1px solid #444; 
	cursor: pointer!important;
	font-weight: normal!important;
}


.TopMenu {
  margin-left: 0px !important;
}
.ui.fluid.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.segment.pushable {
  border-radius: 0px !important;
}
.mainToggleMenu {
  padding-left: 0px !important;
  margin-left: 0px !important;
  width: 50px !important;
}
.mainToggleMenu button {
  margin-top: 5px !important;
  margin-left: 5px !important;
}
.annotationsSegement {
  min-height: 100vh;
  margin-top: 42px !important;
}

.settingsCanvas {
	margin-top: 100px;
    max-width: 625px;
    margin-left: auto;
    margin-right: auto;
}

.bookImage{
  border: solid 1px black!important;
}

div.singleAnnotation .logoHeader{
	box-shadow: none!important;
}
div.singleAnnotation .logoText{
	color: #808080!important;
	font-family: Baskerville serif !important;
	font-size: 20px;
}
div.singleAnnotation .ui.fixed.sticky + .container {
	margin-top: 39px;
}
div.singleAnnotation .header{
	padding: 8px;
	font-style: italic;
	background: transparent;
}
div.singleAnnotation .meta{
	padding-left: 8px;
	margin-bottom: 20px;
}
div.singleAnnotation .annotation{
	max-width: 750px!important;
}
.noteFooter {
	background-color: rgb(199, 204, 205);
	text-align: center;
	padding-top: 20px;
	margin-top: 50px;
	height: 130px;
	font-size: 16px;
	color: white;
	width: 100%;

}
@media (min-height: 700px) {
	.noteFooter {
		bottom: 0;
	}
}
.annotationContent{
	font-size: 21px;
	line-height: 1.58;
	font-family: Georgia,Cambria,"Times New Roman",Times,serif;
}

@media only screen and (max-width: 767px){
	.annotationContent {
		font-size: 18px;
	}
}

.Login--module {
    /*background-color: #DADADA;*/
}

.loginWithGoogle,
.loginButton{
	  margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
}
.loginButton{
    width: 240px;
    border-radius: 0px!important;
    margin-top: 20px!important;
}
.Login--module .ui.message {

}

.Login--centered-link {
    text-align: center;
    display: block;
    margin: 16px auto;
    text-decoration: underline;
    color: blue;
    border: 0;
}

.Login--email-account-container {
    max-width: 400px;
    margin: 40px auto;
    text-align: left;
}

.link {
    cursor:pointer;
    color:#4183c4;
}

#root, .login-form {
    height: 100%;
}

.logoHeader{
	  box-shadow: none!important;
}
.logoText{
	  color: #808080!important;
	  font-family: Baskerville serif !important;
	  font-size: 20px;
}
.detailsContainer {
	  margin-top: 39px;
}

