.noteTable {
  table-layout: fixed;
  width: 100%;
}

.noteIcon {
  vertical-align: top;
  width: 25px;
}

.noteDate {
  vertical-align: top;

  float: right;
  color: rgba(0, 0, 0, 0.4);
}

.dateCreated {
  font-size: 10px;
  color: gray;
  min-width: 65px;
  display: inline-block;
}

.note {
  margin-bottom: 1em;
}
