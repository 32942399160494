@media only screen and (max-width: 768px){
	.showMenu{
		display: block!important;
	}
	.hideMenu{
		display: none!important;
	}
}
.toggle{
	margin-right: auto;
}
.title{
	margin-right: auto;
	margin-left: auto;
	font-weight: bold;
}
.drop{
	margin-left: auto;
}
.menuBookTitle {
  text-overflow: ellipsis;
  max-width: 660px;

  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}