.annotationToolbar button,
.annotationToolbar a{
	margin-right: 10px!important;
}

@media only screen and (min-width: 767px) {
	.annotationToolbar div{
		float: left;
	}
}
@media only screen and (max-width: 767px) {
	.annotationToolbar div{
		text-align: center;
		margin-bottom: 10px;
	}
}

.buttonLink {
	 background:none!important;
	 color:inherit!important;
	 border:none!important; 
	 padding:0!important;
	 font: inherit!important;
	 /*border is optional*/
	 border-bottom:1px solid #444; 
	 cursor: pointer!important;
	 font-weight: normal!important;
}
button.buttonLink:hover {
	color: #1e70bf!important;
}