.TopMenu {
  margin-left: 0px !important;
}
.ui.fluid.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.segment.pushable {
  border-radius: 0px !important;
}
.mainToggleMenu {
  padding-left: 0px !important;
  margin-left: 0px !important;
  width: 50px !important;
}
.mainToggleMenu button {
  margin-top: 5px !important;
  margin-left: 5px !important;
}
.annotationsSegement {
  min-height: 100vh;
  margin-top: 42px !important;
}
