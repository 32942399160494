.Login--module {
    /*background-color: #DADADA;*/
}

.loginWithGoogle,
.loginButton{
	  margin-left: auto;
    margin-right: auto;
    border-radius: 7px;
}
.loginButton{
    width: 240px;
    border-radius: 0px!important;
    margin-top: 20px!important;
}
.Login--module .ui.message {

}

.Login--centered-link {
    text-align: center;
    display: block;
    margin: 16px auto;
    text-decoration: underline;
    color: blue;
    border: 0;
}

.Login--email-account-container {
    max-width: 400px;
    margin: 40px auto;
    text-align: left;
}

.link {
    cursor:pointer;
    color:#4183c4;
}

#root, .login-form {
    height: 100%;
}
