.description div{
    margin-top: 10px;
    margin-bottom: 10px;
}

.exportOptions{
    display: flex;
    flex-direction: row;
}
.exportOptions .field{
    padding-left: 20px;
}

.description button{
    margin-top: 15px;
}

.actions{
    text-align: center!important;
}