.TopMenu {
  margin-left: 0px !important;
}
.ui.fluid.container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.segment.pushable {
  border-radius: 0px !important;
}
.mainToggleMenu {
  padding-left: 0px !important;
  margin-left: 0px !important;
  width: 50px !important;
}
.mainToggleMenu button {
  margin-top: 5px !important;
  margin-left: 5px !important;
}
.annotationsSegement {
  min-height: 100vh;
  margin-top: 42px !important;
  background-color: #f5f7f9!important;
  border-top: 0px!important;
}
.annotationsSegement .pusher{
  background-color: #f5f7f9;
}

/* Page Context fix for sticky sidebar */
.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.ui.sidebar{
  overflow-y: hidden!important;
  margin-top: 44px!important;
}

.ui.sidebar:hover{
  overflow-y: auto!important;
}
.pusher{
  transition: all 0.5s ease!important;
}