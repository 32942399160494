.logoHeader{
	  -webkit-box-shadow: none!important;
	  -moz-box-shadow: none!important;
	  box-shadow: none!important;
}
.logoText{
	  color: #808080!important;
	  font-family: Baskerville serif !important;
	  font-size: 20px;
}
.detailsContainer {
	  margin-top: 39px;
}
