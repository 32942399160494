.bookName {
	font-size: 30px;
}

.annotation {
	text-align: left;
}
.annotation .location{
	float: right;
}
.annotation .location a{
	float: right;
	color: rgba(0,0,0,.4);
}
.annotation div.header{
	font-size: 17px !important;
}
div.notesHeader{
	margin-top: 20px;
	font-size: .9em;
	color: gray;
}
hr.notesHeader{
	margin-top: 0px;
	margin-bottom: 10px;
	border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.annotationContent{
	font-size: 21px;
	line-height: 1.58;
	font-family: Georgia,Cambria,"Times New Roman",Times,serif;
}

@media only screen and (max-width: 767px){
	.annotationContent {
		font-size: 18px;
	}
}
